.footer-title {
  @apply block mb-2 font-title text-xl text-orange uppercase;
}

.footer-links a {
  @apply uppercase text-sm font-title hover:text-orange hover:underline;
}

.footer-alt-links a {
  @apply text-orange;
}

.footer-social-links {
  @apply flex items-center space-x-4;
}

.footer-social-links a {
  @apply text-white hover:underline hover:text-orange;
}