.form-control {
  @apply w-full mb-1.5 px-3 py-2 appearance-none border border-gray-600 focus:border-orange outline-none;
}
.form-control:active {
  @apply border-orange;
}

.form-label {
  @apply text-orange my-0.5;
}
