.card-row {
  @apply flex flex-col;

  > div {
    min-height: 40vh;
  }

  @screen md {
    @apply flex-row items-stretch;

    min-height: 70vh;

    > div {
      min-height: none;
    }
  }
}

.card-open {
  @apply text-white hover:text-orange;

  svg, svg > g {
    @apply transition-colors duration-300 ease-out;
  }

  &:hover svg > g {
    fill: theme('colors.slate');
  }
}