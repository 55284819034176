@screen md {
  .glide__slide {
    transform: scale(0.85);
    transition: transform 0.2s ease-in-out;
  }

  .glide__slide.is-active {
    transform: scale(1);
  }
}

.glide__arrows .glide__arrow {
  @apply absolute;

  top: 40%;
}