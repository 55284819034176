section.pb-content {
  @apply break-words;

  p {
    @apply mb-4;
  }

  h1, h2, h3, h4, h5, h6 {
    @apply leading-tight font-bold;
  }

  h1, h2 {
    @apply text-3xl md:text-4xl my-6;
  }

  h3 {
    @apply text-2xl md:text-3xl mt-6 mb-2;
  }

  h4 {
    @apply text-xl md:text-2xl mt-5 mb-2;
  }

  blockquote {
    @apply italic pl-6 py-2 border-l-4 border-gray-200 my-6;

    cite {
      @apply block my-2 uppercase tracking-wide text-sm not-italic font-semibold;
    }
  }

  ul, ol {
    @apply pl-0 list-inside;
  }

  & li + li {
    @apply mt-1;
  }

  ul li {
    @apply list-disc;
  }

  ol li {
    @apply list-decimal;
  }

  a {
    @apply text-orange;
  }
  a:hover {
    @apply underline;
  }
}

section.pb-image {
  @apply max-w-5xl mx-auto my-10;

  img {
    @apply mx-auto;
  }
}
