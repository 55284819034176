.timeline {
  @apply relative my-12;
}

.timeline .timeline-item {
  @apply relative overflow-hidden mb-28;
}
.timeline .timeline-item:last-child {
  @apply mb-0;
}

.timeline .timeline-item__content {
  @apply relative;
}

.timeline .timeline-left .timeline-item {
  @apply md:pr-16 lg:pr-0;
}
.timeline .timeline-right .timeline-item {
  @apply md:pl-16 lg:pl-0;
}

.timeline .timeline-right .timeline-item:nth-child(even) {
  @apply xl:pr-16;
}
.timeline .timeline-left .timeline-item:nth-child(even) {
  @apply xl:pl-16;
}

.timeline .timeline-segment {
  @apply relative py-16;
}

.timeline .timeline-segment--alt {
  @apply bg-slate;

  section.pb-content {
    @apply text-white;
  }
}

/*
Collapsed
*/

.timeline-collapsed {
  @apply relative my-8;
}

.timeline-collapsed .timeline-item {
  @apply relative mb-12 pl-16;
}

.timeline-collapsed .timeline-item:last-child {
  @apply mb-0;
}

.timeline-collapsed .timeline-segment {
  @apply relative overflow-hidden py-12;
}

.timeline-collapsed .timeline-segment .timeline-item:nth-child(even) {
  @apply pl-28;
}

.timeline-collapsed .timeline-item__content {
  @apply relative;
}

.timeline-collapsed .timeline-segment--alt {
  @apply bg-slate;

  section.pb-content {
    @apply text-white;
  }
}

/*
  Lines
*/

.timeline .timeline-segment:before {
  content: '';
  position: absolute;
  left: 50%;
  top: 0;
  bottom: 0;

  width: 3px;
  height: 100%;
  margin-left: -1.5px;

  @apply bg-slate;
}

.timeline .timeline-segment--alt:before {
  @apply bg-white;
}

.timeline .timeline-item__content:before, .timeline-collapsed .timeline-item__content:before {
  content: '';
  position: absolute;
  top: 40%;
  left: 112%;

  width: 100%;
  height: 3px;

  @apply bg-slate;
}
.timeline .timeline-right .timeline-item__content:before {
  left: -112%;
}
.timeline-collapsed .timeline-item__content:before {
  left: -118%;
  top: 50%;
}
.timeline .timeline-segment--alt .timeline-item__content:before, .timeline-collapsed .timeline-segment--alt .timeline-item__content:before {
  @apply bg-white;
}

.timeline .timeline-item__content:after, .timeline-collapsed .timeline-item__content:after {
  content: '';
  position: absolute;
  z-index: 3;
  top: calc(40% - 4px);
  left: calc(110% - 24px);
  width: 28px;
  height: 10px;

  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='28.243' height='10.243' viewBox='0 0 28.243 10.243'%3E%3Cg data-name='Group 45' fill='none' stroke='%23212738' stroke-linecap='round' stroke-width='3'%3E%3Cpath data-name='Line 7' d='M8.121 2.121l-6 6'/%3E%3Cpath data-name='Line 8' d='M17.121 2.121l-6 6'/%3E%3Cpath data-name='Line 9' d='M26.121 2.121l-6 6'/%3E%3C/g%3E%3C/svg%3E");
}
.timeline .timeline-right .timeline-item__content:after {
  left: calc(-10% - 4px);
}

.timeline-collapsed .timeline-item__content:after {
  top: calc(50% - 4px);
  left: calc(-15% - 4px);
}

.timeline .timeline-segment--alt .timeline-item__content:after, .timeline-collapsed .timeline-segment--alt .timeline-item__content:after {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='28.243' height='10.243' viewBox='0 0 28.243 10.243'%3E%3Cg data-name='Group 45' fill='none' stroke='%23FFFFFF' stroke-linecap='round' stroke-width='3'%3E%3Cpath data-name='Line 7' d='M8.121 2.121l-6 6'/%3E%3Cpath data-name='Line 8' d='M17.121 2.121l-6 6'/%3E%3Cpath data-name='Line 9' d='M26.121 2.121l-6 6'/%3E%3C/g%3E%3C/svg%3E");
}

.timeline:before {
  content: '';
  position: absolute;
  left: 50%;
  top: -32px;

  width: 10px;
  height: 28px;
  margin-left: -5px;

  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='10.243' height='28.243' viewBox='0 0 10.243 28.243'%3E%3Cg data-name='Group 46' fill='none' stroke='%23087f8c' stroke-linecap='round' stroke-width='3'%3E%3Cpath data-name='Line 7' d='M8.121 8.121l-6-6'/%3E%3Cpath data-name='Line 8' d='M8.121 17.121l-6-6'/%3E%3Cpath data-name='Line 9' d='M8.121 26.121l-6-6'/%3E%3C/g%3E%3C/svg%3E");
}

.timeline:after {
  content: '';
  position: absolute;
  left: 50%;
  bottom: -32px;

  width: 10px;
  height: 28px;
  margin-left: -5px;

  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='10.243' height='28.243' viewBox='0 0 10.243 28.243'%3E%3Cg data-name='Group 50' fill='none' stroke='%23f97068' stroke-linecap='round' stroke-width='3'%3E%3Cpath data-name='Line 7' d='M8.121 8.121l-6-6'/%3E%3Cpath data-name='Line 8' d='M8.121 17.121l-6-6'/%3E%3Cpath data-name='Line 9' d='M8.121 26.121l-6-6'/%3E%3C/g%3E%3C/svg%3E");
}
