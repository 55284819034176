.global-menu {
  @apply inset-0 fixed z-30 pointer-events-none invisible;
}

.global-menu__underlay {
  @apply inset-0 fixed z-20 pointer-events-none bg-white;
}

.global-menu__underlay.global-menu--open {
  @apply pointer-events-none;

  transform: scaleX(1);
  transition-delay: 0;
}

.global-menu--open {
  @apply block h-full pointer-events-auto visible;
}

.global-menu__main-links li > a {
  @apply block relative font-title uppercase text-3xl pl-4 lg:text-4xl py-2 xl:text-5xl xl:py-4;

  transition: transform 300ms cubic-bezier(0.25, 1, 0.5, 1), opacity 200ms linear;
  transform: translateX(300px);
  opacity: 0;
}

.global-menu--open .global-menu__main-links li > a {
  transform: translateX(0);
  opacity: 1;
  transition-delay: 100ms;
}

.global-menu--open .global-menu__main-links li > a.button {
  @apply text-orange;
}

@screen sm {
  .global-menu__main-links li > a:before {
    position: absolute;
    left: -36px;
    top: 50%;
    margin-top: -8px;
    width: 44px;
    height: 24px;
    content: '';
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 57.243 31.243'%3E%3Cg data-name='Group 24' fill='none' stroke='%23087f8c' stroke-linecap='round' stroke-width='3'%3E%3Cg data-name='Group 4'%3E%3Cpath data-name='Line 7' d='M16.121 2.121l-13 13'/%3E%3Cpath data-name='Line 8' d='M35.121 2.121l-13 13'/%3E%3Cpath data-name='Line 9' d='M55.121 2.121l-13 13'/%3E%3C/g%3E%3Cg data-name='Group 23'%3E%3Cpath data-name='Line 7' d='M41.121 16.121l13 13'/%3E%3Cpath data-name='Line 8' d='M22.121 16.121l13 13'/%3E%3Cpath data-name='Line 9' d='M2.121 16.121l13 13'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E");

    transition: transform 300ms cubic-bezier(0.25, 1, 0.5, 1), opacity 300ms cubic-bezier(0.25, 1, 0.5, 1);
    transform: translateX(40px);
    opacity: 0;

    @screen lg {
      left: -42px;
      margin-top: -10px;
      width: 50px;
      height: 27px;
    }

    @screen xl {
      left: -50px;
      margin-top: -12px;
      width: 58px;
      height: 31px;
    }
  }

  .global-menu--open .global-menu__main-links li > a:hover:before {
    transform: translateX(0);
    opacity: 1;
  }

  .global-menu__main-links li > a.childless:before {
    transform: translateX(40px) rotateZ(180deg);
  }

  .global-menu--open .global-menu__main-links li > a.childless:hover:before {
    transform: translateX(0) rotateZ(180deg);
  }
}

.global-menu__main-links li:nth-child(2) a { transition-delay: 150ms; }
.global-menu__main-links li:nth-child(3) a { transition-delay: 200ms; }
.global-menu__main-links li:nth-child(4) a { transition-delay: 250ms; }
.global-menu__main-links li:nth-child(5) a { transition-delay: 300ms; }
.global-menu__main-links li:nth-child(6) a { transition-delay: 350ms; }
.global-menu__main-links li:nth-child(7) a { transition-delay: 400ms; }
.global-menu__main-links li:nth-child(8) a { transition-delay: 450ms; }
.global-menu__main-links li:nth-child(9) a { transition-delay: 500ms; }



.global-menu__sub-links li > a {
  @apply block relative font-title uppercase leading-snug text-xl xl:text-2xl my-3 text-orange;

  /*
  transition: transform 300ms cubic-bezier(0.25, 1, 0.5, 1), opacity 200ms linear;
  transform: translateX(100px);
  opacity: 0;

   */
}

.global-menu__sub-links li a:hover {
  @apply text-teal;
}

.global-menu__child-links {
  @apply mb-6;
}

.global-menu__child-links a {
  @apply block relative font-title uppercase text-xl py-1 ml-8 text-slate hover:text-orange;
}