header.global {
  @apply absolute left-0 top-0 right-0 z-50 py-4 transition-colors duration-200 ease-linear;

  .logo {
    @apply mr-auto text-white;
  }
  .logo__sea, .logo__mast {
    fill: theme('colors.white');
  }

  .menu-trigger {
    @apply text-slate hover:text-orange;

    .menu-trigger__bg {
      fill: #FFF;
    }
  }
  .menu-trigger:hover .menu-trigger__bg {
    fill: theme('colors.slate');
  }

  .menu-trigger, .menu-trigger__bg, .logo, .logo__sea, .logo__mast {
    @apply transition-colors duration-300 ease-linear;
  }

  .elearning-button {
    @apply hidden sm:block mr-6 border-2 border-white py-1 px-3 uppercase text-sm leading-6 font-default tracking-wide font-bold text-white transition-colors duration-200 hover:bg-orange;
  }
}

header.header--stuck {
  @apply fixed bg-white shadow;
}

header.header--menu, header.header--stuck {
  .logo {
    @apply text-slate;
  }
  .logo__sea, .logo__mast {
    fill: theme('colors.orange');
  }

  .elearning-button {
    @apply border-orange text-slate;
  }
}

body.no-splash header.global {
  .logo {
    @apply text-slate;
  }
  .logo__sea, .logo__mast {
    fill: theme('colors.orange');
  }
}