.overlay-card {
  @apply block;
  @apply flex justify-between items-center overflow-hidden;

  min-height: 320px;
}

.overlay-card__text {
  @apply flex-shrink-0 pl-10 pr-6 text-white;

  width: 30%;
}

.overlay-card__image {
  @apply relative flex-grow;

  width: 70%;
}

.overlay-card__image img {
  clip-path: polygon(10% 0%, 100% 0%, 100% 100%, 0% 100%);
}