html, body {
  scroll-behavior: smooth;
}

* {
  scroll-margin-top: 100px;
}

hr {
  border-top: 3px solid theme('colors.slate');
}