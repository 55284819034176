.modal-underlay {
  @apply fixed inset-0 flex items-center justify-center;

  z-index: 50;
  background: rgba(0,0,0,0.6);
}
.modal {
  @apply fixed inset-0;

  z-index: 51;
}
.modal-body {
  @apply w-full h-full flex items-center justify-center;
}
.modal-body > div {
  width: 100%;
  height: 100vh;

@screen md {
  height: 70vh;
}
}

.modal__text-block {
  max-height: 40vh;

  @screen md {
    @apply max-h-full;
  }
}

.modal-enter-active {
  animation: bounce-in .4s ease-out both;
}

.modal-leave-active {
  animation: bounce-in .4s reverse ease-in both;
}

.modal-underlay-enter-active {
  animation: fade-in .3s ease-out both;
}

.modal-underlay-leave-active {
  animation: fade-in .3s reverse ease-in both;
}

@keyframes bounce-in {
  0% {
    transform: scale(0.4) translateY(5vh);
    opacity: 0;
  }
  20% {
    opacity: 0;
  }
  100% {
    opacity: 1;
    transform: scale(1) translateY(0);
  }
}

@keyframes fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}