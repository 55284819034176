.splash {
  @apply w-screen;
}

.splash-default {
  height: 92vh;
}
.splash-compact {
  height: 60vh;
}

.splash__overlay {

}

.splash__overlay h1 span {
  opacity: 0;
  animation: splashTitleEnter 0.4s ease-out 0s 1 forwards;
}

@keyframes splashTitleEnter {
  0% {
    opacity: 0;
    transform: translateX(64px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

.splash__overlay h1 span:nth-child(0) { animation-delay: 0.2s; }
.splash__overlay h1 span:nth-child(1) { animation-delay: 0.3s; }
.splash__overlay h1 span:nth-child(2) { animation-delay: 0.4s; }
.splash__overlay h1 span:nth-child(3) { animation-delay: 0.5s; }
.splash__overlay h1 span:nth-child(4) { animation-delay: 0.6s; }
