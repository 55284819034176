.pull-out {
  @apply relative;
}

.pull-out:before {
  content: '';

  @apply bg-slate absolute top-0 left-0 bottom-0 w-1/3 h-full;
}

.pull-out__block {
  @apply relative;
}